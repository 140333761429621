export default {
    enable: "Activer",
    disable: "Désactiver",
    name: "Prénom",
    lastName: "Nom de famille",
    contactName: "Nom du contact",
    commercialName: "Nom de l'entreprise",
    phone: "Téléphone",
    population: "Ville",
    province: "Province",
    hour: "temps",
    save: "Sauvez",
    delete: "Supprimer",
    observations: "Remarques",
    country: "Pays",
    postalCode: "Code postal",
    client: "Client",
    passport: "Code d'identification fiscale / Passeport",
    email: "Courriel",
    suscribe: "S'abonner à",
    message: "Message",
    loading: "Chargement...",
    update: "Mise à jour",
    date: "Date",
    invalidOrderFields: "Votre commande contient des champs invalides",
    brands: "Marques",
    total: "Total",
    showAll: "Afficher tous",
    invoice: "Facture",
    identifier: "Identifiant",
    description: "Description",
    keepShoping: "Poursuivre les achats",
    goCheckout: "Checkout",
    product: "Produit",
    products: "Produits",
    category: "Catégorie",
    categories: "Catégories",
    productCategories: "Catégories de produits",
    barCode: "Codes-barres",
    barCodeHeader: "Code-barres",
    price: "Prix",
    password: "Mot de passe",
    recoverPassword: "Récupération du mot de passe",
    quantity: "Quantité",
    stock: "Stock",
    action: "Action",
    search: "Recherche",
    findByDate: "Filtrer par date",
    searching: "A la recherche...",
    address: "Adresse",
    addProduct: "Ajouter un produit",
    addresses: "Adresses",
    activate: "Activez",
    deactivate: "Désactiver",
    close: "Fermer",
    warning: "Avis",
    create: "Créer",
    back: "Retour à",
    next: "Suivant",
    continue: "Continuer",
    download: "Télécharger",
    reference: "Référence",
    referenceHeader: "Réf.",
    welcome: "Bienvenue à",
    cancel: "Annuler",
    accept: "Accepter",
    send: "Envoyer à",
    end: "Finition",
    notAvaliableServiceHeader: "Désolés !",
    notAvaliableServiceBody: "Ce service n'est pas encore disponible.",
    noResults: "Il n'y a pas de résultats.",
    emptyLabel: "Veuillez remplir ce champ",
    invalidPostalCode: "Code postal non valide",
    minimumLength: "Le champ doit comporter au moins %numberSlot% caractères.",
    maximusLength: "Le champ doit avoir un maximum de %numberSlot% caractères.",
    characters: "Personnages",
    clone: "Clone",
    invalidEmail: "Courriel non valide",
    requiredEmail: "Courriel requis",
    requiredInput: "Champ obligatoire",
    requiredPassword: "Mot de passe requis",
    invalidDyn_1: "Le champ",
    invalidDyn_2: "est invalide.",
    minimunSearch_1: "Votre recherche doit contenir au moins 3 lettres.",
    minimunSearch_2: "lettres.",
    notMatchingPasswords: "Les mots de passe saisis ne correspondent pas.",
    deleteAddressConfirmation: "Êtes-vous sûr de vouloir supprimer cette adresse ?",
    createAddressConfirmation: "Êtes-vous sûr de vouloir créer cette adresse ?",
    activateClientConfirmation: "Voulez-vous activer cet utilisateur ?",
    deactivateClientConfirmation: "Voulez-vous désactiver cet utilisateur ?",
    createClientConfirmation: "Etes-vous sûr de vouloir créer le client ?",
    clearCartConfirmation: "Vous êtes sûr de vouloir vider le panier ?",
    orderConfirmation: "Voulez-vous créer cette commande ?",
    patchOrderConfirmation: "Êtes-vous sûr de vouloir modifier votre commande ? Les modifications seront irréversibles une fois que la commande aura été soumise.",
    deleteOrderConfirmation: "Si vous continuez, votre commande sera supprimée.",
    updatePasswordConfirmation: "Êtes-vous sûr de vouloir mettre à jour votre mot de passe ?",
    politicConfirmation: "J'ai lu et j'accepte la politique de confidentialité.",
    home: "Accueil",
    novelties: "Nouvelles",
    videos: "Vidéos",
    contact: "Contact",
    company: "Entreprise",
    personalData: "Données personnelles",
    order: "Commandez",
    orders: "Commandez",
    createOrder: "Créer une commande",
    invoices: "Factures",
    promo: "Promotions",
    notifications: "Notifications",
    favourites: "Favoris",
    clients: "Clients",
    login: "Connexion",
    logout: "Déconnexion",
    nothing: "Rien",
    nothingToShow: "Rien à afficher",
    myProfile: "Mon profil",
    replacePassword: "Remplacer mon mot de passe",
    replacePasswordLabel_1: "Mot de passe actuel",
    replacePasswordLabel_2: "Nouveau mot de passe",
    replacePasswordLabel_3: "Répéter le nouveau mot de passe",
    advertisement: "Abonnez-vous à notre bulletin d'information et restez informé de toutes nos nouvelles et de nos derniers communiqués.",
    advertisementCheckBox: "J'ai lu et j'accepte la politique",
    inProgressStatus: "En cours",
    waitingStatus: "En attente",
    sendStatus: "Envoyé à",
    finishStatus: "Fini",
    draftStatus: "Draft",
    canceledStatus: "Annulé",
    retrasedStatus: "Retardé",
    orderRefecence: "Référence de la commande",
    orderReferenceHeader: "Référence de commande",
    buyDateAtHeader: "Date d'achat",
    stateHeader: "Statut",
    invoiceNumber: "Numéro de facture",
    invoiceNumberHeader: "Facture n.",
    notFoundInvoices: "Aucune facture trouvée.",
    newClient: "Nouveau client",
    createClient: "Créer un client",
    deactivatedClients: "Clients désactivés",
    noAvaliableClients: "Aucun client disponible",
    clientInfo: "Informations sur les clients",
    clientFile: "Dossier client",
    createAddress: "Créer une adresse de livraison",
    newAddress: "Nouvelle adresse de livraison",
    newDeliveryAddress: "Nouvelle adresse de livraison",
    sendAddress: "Adresse de livraison",
    logoutWarning: "Si vous continuez, vous serez déconnecté.",
    searchTip_1: "Vous n'êtes pas satisfait des résultats ?",
    searchTip_2: "Rechercher avec un nouveau mot",
    notFoundProducts: "Aucun produit trouvé.",
    estimatedStockDate: "Date de disponibilité approximative",
    boxQuantity: "Unités par boîte",
    minimumBuyQuantity: "Unités minimales de vente",
    downloadFileProduct: "Télécharger la fiche produit",
    noAvaliableProducts: "Aucun produit disponible.",
    expandCart: "Payer",
    emptyCartMessage: "Il n'y a aucun produit dans votre panier.",
    footerLeftInfo: "Des questions ? L'équipe Perfect est là pour vous aider.",
    footerRightInfo: "Si vous avez des doutes ou des questions techniques sur l'un de nos produits, écrivez-nous et notre technicien ou notre représentant commercial prendra contact avec vous.",
    legalWarn: "Avis juridique",
    privacityPolitics: "Politique de confidentialité",
    cookiesPolitics: "Politique en matière de cookies",
    checkout: "Passer une commande",
    deliveryAddress: "Adresse de livraison",
    orderSummary: "Résumé de la commande",
    backCart: "Retour au panier",
    noAvaliableOrders: "Aucune commande disponible",
    taxBase: "Assiette fiscale",
    taxBaseHeader: "Assiette fiscale",
    pvpTaxBaseHeader: "Assiette fiscale PVP",
    commerciaRate: "PVP",
    noProductsInCart: "Vous devez ajouter au moins un produit pour continuer",
    downloadHere: "Télécharger ici",
    slogan_1: "Perfect Beauty est synonyme de...",
    slogan_2: "\Design, innovation, technologie et passion...",
    intro_1: "Chez Perfect Beauty, nous continuons à évoluer et à investir pour obtenir un produit de la plus haute qualité, à la pointe de la technologie. Pour cela, nous avons opté pour la création d'une équipe de R & D afin d'atteindre le maximum de qualité, d'efficacité et d'opérabilité dans chacun des nouveaux produits que nous mettons sur le marché. Un autre des piliers de Perfect Beauty est le contrôle de la qualité, par lequel passe chacun de nos produits et des pièces qui le composent, ce qui nous donne une fiabilité envers nos clients supérieure à n'importe quelle marque de produits électriques. Grâce à notre service technique, nous veillons à ce que toutes les garanties soient respectées.",
    intro_2: "En bref, un groupe de personnes dédiées à répondre, informer et résoudre toutes les questions ou suggestions de nos clients.",
    newsletter: "Abonnez-vous à notre bulletin d'information et restez informé de toutes nos nouvelles et de nos derniers communiqués.",
    watchMap: "Voir la carte",
    cataloguePDF: "Catalogue PDF",
    companyIntro_1: "Des produits utilisés par les professionnels des plus grands salons de beauté et de coiffure. Nous continuons à élargir les possibilités et à ouvrir les horizons des professionnels de la coiffure et de l'esthétique. Toujours mis à jour, avec les dernières nouveautés et les plus innovantes pour répondre à tous les besoins du secteur.",
    companyIntro_2: "Perfect Beauty SL, une entreprise fondée par trois professionnels ayant plus de 30 ans d'expérience dans le secteur de la coiffure et de l'esthétique, avec pour mission de fournir des produits de qualité et innovants au canal professionnel. Une entreprise qui s'attache à fournir le meilleur service à un prix compétitif. Pour cela, nous disposons d'une équipe humaine de 25 professionnels, répartis dans différents départements : service clientèle, ventes, contrôle qualité, service technique, R & D & I., marketing et administration, avec 600 m2 de bureaux, et un entrepôt de 7000 m2.",
    companyIntro_3: "Notre objectif est de fournir aux professionnels une grande variété de produits pour faciliter le travail dans les secteurs de la coiffure, du barbier et de la beauté. Nous mettons à la disposition de nos clients un catalogue composé de plus de 3000 références, qui est mis à jour deux fois par an afin d'être fidèle à la mission de notre entreprise qui est d'être à l'avant-garde du secteur avec des produits innovants.",
    companyIntro_4: "Le contrôle de la qualité comme élément de différenciation fait de nous une référence sur le marché. Grâce aux ressources allouées par l'entreprise pour le contrôle du produit tant à l'origine qu'à la destination, il nous désigne comme une entreprise de garantie. Notre service après-vente s'ajoute à cet engagement de clients satisfaits. Notre propre département de recherche et développement nous garantit d'être innovants dans les conceptions, les composants et les nouvelles utilités des produits. Une équipe dédiée à maintenir la marque Perfect Beauty comme une entreprise dynamique qui fournit des solutions aux professionnels de la coiffure, du barbier et de l'esthétique.",
    companyIntro_5: "La direction de Perfect Beauty est consciente de la pertinence de la contribution de tous, entreprises, institutions, administrations publiques et individus, dans la promotion de pratiques responsables qui respectent l'environnement et qui permettent une avancée claire de la société vers un monde meilleur. C'est pour cette raison que l'intégration de directives environnementales dans sa stratégie d'entreprise a été imposée. Ces orientations sont concrétisées dans la Politique environnementale, qui s'inspire de deux principes : le respect de toutes les réglementations environnementales applicables et l'amélioration continue des activités afin de protéger l'environnement. Avec ces deux principes, l'entreprise s'engage à prévenir, protéger et conserver l'environnement.",
    companyIntro_6: "Pour mettre en œuvre ces principes, la direction de Perfect Beauty propose : - D'adopter les mesures nécessaires pour prévenir la pollution, et lorsque cela n'est pas possible, de réduire au minimum les émissions polluantes. - Réduire systématiquement les déchets, en les recyclant et en les réutilisant chaque fois que possible, ainsi qu'en utilisant efficacement les ressources naturelles, les matières premières et l'énergie. - Appliquer les mesures nécessaires pour garantir le respect de la réglementation environnementale applicable à nos activités au niveau local, national et mondial dans lequel l'entreprise opère. - Diffuser la politique environnementale auprès des employés, des fournisseurs et des autres parties intéressées. - Former et éduquer les employés afin que, dans leur travail quotidien, ils s'acquittent de leurs tâches conformément à la politique environnementale. - Établir des procédures pour l'examen périodique du respect de la politique environnementale, et appliquer des mesures correctives en cas de violation de celle-ci. - Mener à bien un processus d'amélioration continue de nos performances en matière d'environnement.",
    corporativeVideo: "Vidéo d'entreprise",
    noAddedProducts: "Il n'y a pas de produits à acheter.",
    favouriteAdded: "Le produit a été marqué comme favori.",
    username: "Nom d'utilisateur.",
    dear: "Cher",
    orderSuccesfullySended: "Votre commande a été envoyée avec succès.",
    orderSendedMessageQuery_1: "Vous recevrez un résumé de votre commande dans votre e-mail.",
    thanks: "Merci beaucoup.",
    commercialDepartament: "Le département commercial de Perfect Beauty.",
    seeOrders: "Voir les commandes",
    clearFilters: "Effacer les filtres",
    inStock: "En stock",
    noStock: "Pas de stock",
    filterByProduct: "Filtrer par produit",
    filterByDate: "Filtrer par date",
    filterByStatus: "Filtrer par statut",
    filterByClient: "Filtrer par client",
    fiscalName: "Nom fiscal",
}