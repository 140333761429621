




































import {Component, Prop, Ref, Vue} from "vue-property-decorator"
import LoginService from "@/services/LoginService"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import {getModule} from "vuex-module-decorators"
import StringTool from "@/services/tool/StringTool"
import LangModule from "@/store/LangModule"
import PasswordService from "@/services/PasswordService"
import jsPDF from "jspdf";
import RulesTool from "@/services/tool/RulesTool";

@Component
export default class RecoverPasswordDialog extends Vue {

	@Ref() readonly form!: HTMLFormElement
	@Prop() readonly dialog!: boolean

	email: string = ""
	rules = RulesTool

	lang: any = getModule(LangModule).lang

	close() {
		this.$emit('close')
	}

	async requestRecovery() {
		if (this.form.validate()) {
			await PasswordService.requestRecovery(this, this.email)
			this.$emit('successRequestedRecovery')
		}
	}
}

