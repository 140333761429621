export default {
  enable: "Activate",
  disable: "Deactivate",
  name: "Name",
  lastName: "Last name",
  contactName: "Contact Name",
  commercialName: "Tradename",
  phone: "Phone",
  population: "Population",
  province: "Province",
  hour: "hour",
  save: "Save",
  delete: "Delete",
  observations: "Observations",
  country: "Country",
  postalCode: "Postal Code",
  client: "Client",
  passport: "N.I.F / C.I.F / Passport",
  email: "E-mail",
  suscribe: "Subscribe",
  message: "Message",
  loading: "Loading...",
  update: "Update",
  date: "Date",
  invalidOrderFields: "There's invalid fields in your order.",
  brands: "Trademarks",
  total: "Total",
  showAll: "Show all",
  invoice: "Bill",
  identifier: "Identifier",
  description: "Description",
  keepShoping: "Continue buying",
  goCheckout: "Checkout",
  product: "Product",
  products: "Products",
  category: "Category",
  categories: "Categories",
  productCategories: "Product Categories",
  barCode: "Barcode",
  barCodeHeader: "Bar Code",
  price: "Price",
  password: "Password",
  recoverPassword: "Recover password",
  quantity: "Quantity",
  stock: "Stock",
  action: "Action",
  search: "Search",
  findByDate: "Find by order's date...",
  searching: "Searching...",
  address: "Direction",
  addProduct: "Add Product",
  addresses: "Addresses",
  activate: "Activate",
  deactivate: "Deactivate",
  close: "To close",
  warning: "Warning",
  create: "To create",
  back: "Return",
  next: "Following",
  continue: "Continue",
  download: "Download",
  reference: "Reference",
  referenceHeader: "Ref.",
  welcome: "Welcome",
  cancel: "Cancel",
  accept: "Accept",
  send: "Send",
  end: "End",
  notAvaliableServiceHeader: "We are sorry!",
  notAvaliableServiceBody: "This service is not yet available.",
  noResults: "No results.",
  emptyLabel: "Please fill in this field",
  invalidPostalCode: "Invalid zip code",
  minimumLength: "The field must be at least% numberSlot% characters.",
  maximusLength: "The field must have a maximum of% numberSlot% characters.",
  characters: "Characters",
  clone: "Clone",
  invalidEmail: "Invalid email",
  requiredEmail: "Email required",
  requiredInput: "Required field",
  requiredPassword: "Password required",
  invalidDyn_1: "Field",
  invalidDyn_2: "is invalid.",
  minimunSearch_1: "Your search should contain at least 3 letters",
  minimunSearch_2: "letters.",
  notMatchingPasswords: "The passwords entered do not match.",
  deleteAddressConfirmation: "Are you sure to delete this Address?",
  createAddressConfirmation: "Are you sure to create this Address?",
  activateClientConfirmation: "Do you want to activate this user?",
  deactivateClientConfirmation: "Do you want to deactivate this user?",
  createClientConfirmation: "Are you sure to create the client?",
  clearCartConfirmation: "Are you sure to clean the cart?",
  orderConfirmation: "Do you want to create this order?",
  patchOrderConfirmation: "Do you want to edit your order? The changes are irreversible once the order has been sent.",
  deleteOrderConfirmation: "Do you want to delete your order?.",
  updatePasswordConfirmation: "Are you sure to update your password?",
  politicConfirmation: "I have read and accept the privacy policy.",
  home: "Home",
  novelties: "What's new?",
  videos: "Videos",
  contact: "Contact",
  company: "Business",
  personalData: "Personal data",
  order: "Order",
  orders: "Orders",
  createOrder: "Create order",
  invoices: "Invoices",
  promo: "Promotions",
  notifications: "Notifications",
  favourites: "Favorites",
  clients: "Clients",
  login: "Login",
  logout: "Logout",
  nothing: "Nothing",
  nothingToShow: "Nothing to show",
  myProfile: "My profile",
  replacePassword: "Replace my password",
  replacePasswordLabel_1: "Current access key",
  replacePasswordLabel_2: "New password",
  replacePasswordLabel_3: "Repeat new password",
  advertisement: "Subscribe to our newsletter and stay informed of all our news and latest releases.",
  advertisementCheckBox: "I have read and accept the policy",
  inProgressStatus: "In progress",
  waitingStatus: "On hold",
  sendStatus: "Sent",
  finishStatus: "Finalized",
  draftStatus: "Draft",
  canceledStatus: "Cancelled",
  retrasedStatus: "Delayed",
  orderRefecence: "Order Reference",
  orderReferenceHeader: "Order Ref.",
  buyDateAtHeader: "Purchase Date",
  stateHeader: "Status",
  invoiceNumber: "Invoice number",
  invoiceNumberHeader: "Invoice No°",
  notFoundInvoices: "No invoices found.",
  newClient: "New client",
  createClient: "Create client",
  deactivatedClients: "Deactivated clients",
  noAvaliableClients: "No clients available",
  clientInfo: "Client data",
  clientFile: "Client file",
  createAddress: "Create shipping address",
  newAddress: "New address",
  newDeliveryAddress: "New shipping address",
  sendAddress: "Shipping Address",
  logoutWarning: "If you continue, your session will end.",
  searchTip_1: "Unhappy with the results?",
  searchTip_2: "Search using a new word",
  notFoundProducts: "No products found.",
  estimatedStockDate: "Approximated availability date",
  boxQuantity: "Units per box",
  minimumBuyQuantity: "Minimum sales units",
  downloadFileProduct: "Download product sheet",
  noAvaliableProducts: "There are no products available.",
  expandCart: "Pay",
  emptyCartMessage: "There are no products in the cart.",
  footerLeftInfo: "Questions? The Perfect team is here to help.",
  footerRightInfo: "If you have any questions or technical queries about any of our products, write to us and our technician or salesperson will contact you.",
  legalWarn: "Legal warning",
  privacityPolitics: "Privacy Policy",
  cookiesPolitics: "Cookies policy",
  checkout: "Checkout",
  deliveryAddress: "Delivery address",
  orderSummary: "Order summary",
  backCart: "Back to cart",
  noAvaliableOrders: "No orders available",
  taxBase: "Tax base",
  taxBaseHeader: "B. taxable",
  pvpTaxBaseHeader: "B. taxable PVP",
  commerciaRate: "P.V.P",
  noProductsInCart: "You must add at least one product to continue",
  downloadHere: "Download here",
  slogan_1: "Perfect Beauty is synonymous with ...",
  slogan_2: "\"design, innovation, technology and passion\"",
  intro_1: "At Perfect Beauty we continue to evolve and invest to achieve the highest quality, cutting-edge and technology product, for this we have opted for the creation of an R&D team in order to bequeath the maximum quality, efficiency, and operability in each one. of the novelties that we bring to the market. Another of the pillars in Perfect Beauty is Quality Control, through which each of our products and parts that compose it pass, providing us with a reliability towards our customers that is superior to any brand of electrical appliances. With our Technical Service we ensure that all guarantees are fulfilled.",
  intro_2: "In short, a group of people destined to respond, inform and solve any doubt or suggestion from our clients.",
  newsletter: "Subscribe to our newsletter and stay informed of all our news and latest releases.",
  watchMap: "See map",
  cataloguePDF: "PDF Catalog",
  companyIntro_1: "Products used by professionals in the most prominent beauty and hairdressing salons. We continue to expand the possibilities and open horizons for hairdressing and aesthetic professionals. Always updated, with the latest and most innovative to meet any need in the sector.",
  companyIntro_2: "Perfect Beauty SL, a company founded by three professionals with more than 30 years of experience in the hairdressing and aesthetics sector with the mission of providing quality and innovative products to the professional channel. A company focused on providing the best service at a competitive price. For this we have a human team of 25 professionals, distributed in different departments; customer service, sales, quality control, technical service, R & D & I., marketing and administration, with 600 m2 of offices, and a 7000 m2 warehouse.",
  companyIntro_3: "Our goal is to provide professionals with a wide variety of products to facilitate work in the hairdressing, barbering and aesthetics sectors. We make available to our clients a catalog made up of more than 3,000 references, which is updated twice a year in order to be faithful to our company mission of being at the forefront of the sector with innovative products.",
  companyIntro_4: "Quality control as a differentiating element makes us a benchmark in the market. Thanks to the resources allocated by the company for the control of the product both at origin and destination, it nominates us as a guarantee company. Our after sales service adds to this commitment of satisfied customers. Our own research and development department guarantees us to be innovative in designs, components and new product utilities. A team dedicated to maintaining the Perfect Beauty brand as a dynamic company that provides solutions to the professional Hairdresser, Barbershop and Aesthetics.",
  companyIntro_5: "The management of Perfect Beauty is aware of the relevance of the contribution of everyone, companies, institutions, public administrations and individuals, in the promotion of responsible practices that respect the environment and that allow a clear advance of society towards a better world It is for this reason that the integration of environmental guidelines into its business strategy has been imposed. These guidelines are embodied in the Environmental Policy, which is inspired by two principles: compliance with all applicable environmental regulations and continuous improvement of activities in order to protect the environment. With both principles it is committed to the prevention, protection and conservation of the environment.",
  companyIntro_6: "To carry out these principles, the management of Perfect Beauty proposes: - Adopt the necessary measures to prevent pollution, and when this is not possible, reduce polluting emissions to a minimum. - Systematically reduce waste, recycling and reusing it whenever possible, as well as efficiently using natural resources, raw materials and energy. - Apply the necessary measures to guarantee compliance with the environmental regulations applicable to our activities at the local, national and global level in which the company operates. - Disseminate the environmental policy among employees, suppliers, and other interested parties. - Train and educate employees so that in their day-to-day work they carry out their performance following the environmental policy. - Establish procedures for the periodic review of compliance with the environmental policy, as well as apply corrective measures when these have been breached. - Carry out a process of continuous improvement of our performance with respect to the environment.",
  corporativeVideo: "Corporate video.",

  //Recientes...
  noAddedProducts: "There's no products to buy.",
  favouriteAdded: "This product has been marked as favourite.",
  username: "Username",
  dear: "Dear",
  orderSuccesfullySended: "Your order has been sent successfully.",
  orderSendedMessageQuery: "You will receive a summary of your order in your email",
  thanks: "Thank you very much.",
  commercialDepartament: "Perfect Beauty's Commercial Department.",
  seeOrders: "See orders",
  clearFilters: "Clear filters",
  inStock: "In Stock",
  noStock: "No Stock",
  filterByProduct: "Filter by product",
  filterByDate: "Filter by date",
  filterByStatus: "Filter by status",
  filterByClient: "Filter by client",
  fiscalName: "Fiscal",

  errorUnlogging: "An error occurred while trying to log out.",
  successLogin: "Session started successfully."

}