export default class ConstantTool {

  static readonly FIREBASE_BASE_URL: string = "https://firebasestorage.googleapis.com/v0/b/project-vue-28d9c.appspot.com/o/"
  static readonly FIREBASE_SUFFIX: string = "?alt=media"

  static readonly BASE_URL = process.env.VUE_APP_BASE_URL
  static readonly CLIENT_SECRET = process.env.VUE_APP_CLIENT_SECRET
  static readonly ENCRYPT_SESSION: boolean = process.env.VUE_APP_ENCRYPT_SESSION === "true"
  static readonly FIREBASE = process.env.VUE_APP_FIREBASE === "true"

  static readonly GOOGLE_MAPS = process.env.VUE_APP_GOOGLE_MAPS === "true"

  static readonly PROJECT_NAME = "Perfect Beauty"

}
